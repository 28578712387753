<template>
  <div>
    <div class="float-left week-nav">
      <a-button @click="showPreviousWeek" icon="left-square" v-tooltip.top-center="'Nedēļu atpakaļ'"></a-button>
      <span class="pl-2">
          <a-button @click="showNextWeek" icon="right-square" v-tooltip.top-center="'Nedēļu uzpriekšu'" :disabled="week===1"></a-button>
        </span>
    </div>
    <div class="infoBlock text-center">
      <div>
        <h4>{{ device.buildingName }}: {{ device.roomGroupName }} </h4>
      </div>
    </div>
    <vue-plotly :data="data" :layout="layout" :options="options" v-if="hasData"/>
    <div class="text-center" v-if="!loading && !hasData">
      <h1 class="no-data">Nav datu</h1>
    </div>
  </div>
</template>

<script>

const hourSelectOptions = {
  buttons: [
    {
      step: "all",
      label: "viss"
    }
  ]
};

const chartSettings =
  {
    y1_min: 0,
    y1_max: 80,
    y2_min: 0,
    y2_max: 4500,
    options: [
      {
        key: 'temperature',
        name: 'C° temperatūra',
        axis: 'y1',
        type: 'line',
      },
      {
        key: 'humidity',
        name: '% Relatīvais mitrums',
        axis: 'y1',
        type: 'line'
      },
      {
        key: 'co2',
        name: 'CO<sub>2</sub> līmenis',
        axis: 'y2',
        type: 'line'
      },
    ]
  }


import VuePlotly from "@statnett/vue-plotly";

export default {
  name: "DeviceCharts",
  components: {
    VuePlotly
  },
  props: {
    deviceId: Number,
    captchaToken: null,
  },
  data() {
    return {
      week: 1,
      device: {},
      data: [],
      loading: true
    }
  },
  computed: {
    layout: function () {
      const settings = chartSettings

      const yaxis = {
        fixedrange: true,
        range: [settings.y1_min, settings.y1_max]
      }

      const yaxis2 = {
        titlefont: { color: "rgb(148, 103, 189)" },
        tickfont: { color: "rgb(148, 103, 189)" },
        overlaying: "y",
        side: "right",
        fixedrange: true,
        range: [settings.y2_min, settings.y2_max]
      }

      const layoutSettings = {
        height: 650,
        margin: {
          l: 35,
          r: 35,
          b: 30,
          t: 30,
          pad: 0
        },
        title: false,
        showlegend: true,
        legend: { orientation: "h" },
        yaxis: yaxis,
        yaxis2: yaxis2,
        xaxis: {
          rangeselector: hourSelectOptions
        }
      }

      return layoutSettings
    },
    options: function () {
      return {
        displayModeBar: false
      };
    },
    hasData: function () {
      return this.data.length > 0
    }
  },
  async created() {
    if (this.captchaToken) {
      await this.loadChartData();
    }
  },
  methods: {
    async loadChartData() {
      if (!this.captchaToken) {
        return
      }
      this.loading = true;
      this.$startLoading()
      let allData = null
      try {
        const req = {
          deviceId: this.deviceId,
          week: this.week,
          captchaToken: this.captchaToken
        }
        const resp = await this.$http.post('/api/device/chart/', req)
        allData = resp.data.data
        this.data = []
        this.device = resp.data.device
      } finally {
        this.$finishLoading()
      }

      const timeData = allData['timestamp']
      if (!timeData || timeData.length === 0) {
        this.loading = false
        return
      }

      this.$emit("buildingLoaded", this.device.buildingName)

      chartSettings.options.forEach(it => {
        const dataPoints = allData[it.key]
        if (dataPoints.length > 0) {
          const dataLine = {
            x: allData['timestamp'].map(it => new Date(it)),
            y: dataPoints,
            name: it.name,
            type: 'scatter',
            mode: 'lines',
            connectgaps: true,
            line: { width: it.key === 'co2' ? 2.5 : 1.5 },
            yaxis: it.axis,
          };

          if (it.type === 'area') {
            dataLine.fill = 'tozeroy'
          }

          if (it.color) {
            dataLine.line.color = it.color
          }

          this.data.push(dataLine)
        }
      })
      this.loading = false
    },
    async showPreviousWeek() {
      this.week++
      await this.loadChartData()
    },
    async showNextWeek() {
      this.week--
      if (this.week < 1) {
        this.week = 1
      }
      await this.loadChartData()
    }
  }
}

</script>

<style scoped lang="scss">
.infoBlock {
  margin: auto;
  height: 40px;
}

.week-nav {
  padding-left: 2.25rem;
  margin-top: 2px;
}

.no-data {
  font-size: 2rem;
  margin-top: 4rem;
}
</style>
