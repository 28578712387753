<template>
  <div class="mt-2 ml-2">
    <!--vue-hcaptcha
      sitekey="5dfbeaed-495c-4195-b113-1e17cb99bd5f"
      @verify="onCaptchaVerify"
      @expired="onBadCaptcha"
      @challengeExpired="onBadCaptcha"
      @error="onBadCaptcha"
      v-if="!captchaToken"
    /-->
    <charts :deviceId="deviceId" :captchaToken="captchaToken" v-if="captchaToken" ref="chart" @buildingLoaded="buildingLoaded"/>
  </div>
</template>

<script>

import Charts from "./Charts"
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: "DeviceChartPage",
  components: {
    Charts,
    VueHcaptcha
  },
  data() {
    const deviceId = this.$route.params.id;
    return {
      deviceId: deviceId,
      buildingName: null,
      captchaToken: '-1'
    }
  },
  computed: {
    routeTab: function () {
      return this.buildingName
    }
  },
  methods: {
    async onCaptchaVerify(token, ekey) {
      this.captchaToken = token;
      await this.$refs.chart.loadChartData(7)
    },
    onBadCaptcha() {
      this.captchaToken = null;
    },
    buildingLoaded(name) {
      this.buildingName = name
    }

  }
}

</script>

<style scoped lang="scss">

</style>
